export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        jQuery(document).ready(function ($) {
            $('.single-product .woocommerce-product-gallery__image > a').attr('data-fancybox', 'group');
        })

        $('.slick-slider').css('opacity', '1');



        $('.layer__usp__flex .layer__usp__inner').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1299,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        // Add active class
        $('.js-product-sort-button').click(function () {
            $('.js-product-sort-button').removeClass('active');
            $(this).addClass('active');
        });

        // Show submenu
        $('.js-categories-more').click(function () {
            $(this).parent('li').toggleClass('active');
        });

        // Show filters
        $('.js-show-filters').click(function () {
            $(this).parent('li').toggleClass('active');
            $('.product__filters').toggleClass('d-none');
        });

        $('.js-slider-assortment').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            arrows: true,
            swipeToSlide: true,
            nextArrow: '<div class="assortment__slider__next"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg></div>',
            prevArrow: '<div class="assortment__slider__prev"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg></div>',
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 767,
                    settings: 'unslick',
                },
            ],
        });

        // Select all links with hashes
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top,
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(':focus')) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }
                        });
                    }
                }
            });

        let productInfoReadmore = $('.js-product-info-readmore');
        jQuery(productInfoReadmore).readmore({
            collapsedHeight: 185,
            speed: 1500,
            moreLink: '<a href="#" class="product__single__more read-more">Lees meer</a>',
            lessLink: '<a href="#" class="product__single__more product__single__more--close">Lees minder</a>',
            beforeToggle: function (expanded) {
                if (expanded) { // The "Close" link was clicked
                    productInfoReadmore.toggleClass('active');
                    productInfoReadmore.attr('aria-expanded', 'true');
                }
            },
        });


        let productSpecificationsReadmore = $('.js-product-specifications-readmore');
        jQuery(productSpecificationsReadmore).readmore({
            collapsedHeight: 142,
            speed: 1500,
            moreLink: '<a href="#" class="product__single__more read-more">Bekijk alle specificaties</a>',
            lessLink: '<a href="#" class="product__single__more product__single__more--close">Verberg specificaties</a>',
            beforeToggle: function (expanded) {
                if (expanded) { // The "Close" link was clicked
                    productSpecificationsReadmore.toggleClass('active');
                    productSpecificationsReadmore.attr('aria-expanded', 'true');
                }
            },
        });


        let productKenmerkenReadmore = $('.js-product-kenmerken-readmore');
        jQuery(productKenmerkenReadmore).readmore({
            collapsedHeight: 152,
            speed: 1500,
            moreLink: '<a href="#" class="product__single__more read-more">Bekijk alle kenmerken</a>',
            lessLink: '<a href="#" class="product__single__more product__single__more--close">Verberg kenmerken</a>',
            beforeToggle: function (expanded) {
                if (expanded) { // The "Close" link was clicked
                    productKenmerkenReadmore.toggleClass('active');
                    productKenmerkenReadmore.attr('aria-expanded', 'true');
                }
            },
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
