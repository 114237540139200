export const headerPage = {
    init() {

        const tabsComponent = document.querySelectorAll('[data-tabs]');

        tabsComponent.forEach((tabsContainer) => {
            let selectedId = null;

            const tablist = tabsContainer.querySelector('[data-tablist]');
            const tabs = tablist.querySelectorAll('button[role="tab"]');
            const panels = tabsContainer.querySelectorAll('[role="tabpanel"]');

            function select(id)
            {
                selectedId = id;
                updateTabs();
                updatePanels();
            }

            function isSelected(id)
            {
                return selectedId === id;
            }

            function updateTabs()
            {
                tabs.forEach(tab => {
                    const tabId = tab.id;
                    tab.setAttribute('tabindex', isSelected(tabId) ? '0' : '-1');
                    tab.setAttribute('aria-selected', isSelected(tabId));
                    tab.classList.toggle('selected', isSelected(tabId));
                    tab.classList.toggle('unselected', !isSelected(tabId));
                });
            }

            function updatePanels()
            {
                panels.forEach(panel => {
                    const panelId = panel.getAttribute('aria-labelledby');
                    panel.style.display = isSelected(panelId) ? 'block' : 'none';
                });
            }

            tabs.forEach(tab => {
                tab.addEventListener('click', () => select(tab.id));
                tab.addEventListener('focus', () => select(tab.id));
            });

            // Initialize the first tab
            select(tabs[0].id);
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
