export default {
    init() {
        /* eslint-disable */
        $(document).ready(function() {
            $('.single-product.woocommerce .product__image a').each(function() {
                // Calculate aspect ratio and store it in HTML data- attribute
                let aspectRatio = $(this).width()/$(this).height();
                $(this).data("aspect-ratio", aspectRatio);

                // Conditional statement
                if(aspectRatio > 1) {
                    // Image is landscape
                    $(this).addClass('landscape');
                } else if (aspectRatio < 1) {
                    // Image is portrait
                    $(this).addClass('portrait');
                } else {
                    // Image is square
                    $(this).addClass('square');
                }
            });
        });
        /* eslint-enable */

        $('.product__popup .single_add_to_cart_button').on('click', function() {
            $.fancybox.close();
        });

        $('.product__images__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            fade: false,
            prevArrow: $('.product__images__prev'),
            nextArrow: $('.product__images__next'),
            asNavFor: '.product__thumb__slider',
        });
        $(document).on('change', '.variations select', function () {
            const $Selectedselectboxes = $(document).find('.variations select option:selected');
            const $selectboxes = $(document).find('.variations select');
            if ($selectboxes.length === $Selectedselectboxes.length) {
                $(document).find('.product__thumb__slider:first').slick('slickGoTo', 0);
            }
        });
        $('.product__thumb__slider').slick({
            slidesToShow: 4,
            infinite: false,
            slidesToScroll: 1,
            asNavFor: '.product__images__slider',
            dots: false,
            arrows: false,
            swipeToSlide: true,
            focusOnSelect: true,
            verticalSwiping: true,
            vertical: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        vertical: false,
                    },
                },
            ],
        });

        $('.landing__usp__slider').slick({
            dots: false,
            infinite: false,
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1499,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        autoplaySpeed: 3000,
                    },
                },   {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        autoplaySpeed: 3000,
                    },
                },
            ],
        });

        jQuery(document).ready(function ($) {
            // Voeg een delay van 500ms toe voor de eerste functie
            setTimeout(function () {
                const isMobile = $(window).width() < 768; // Controleer of het scherm mobiel is
                const $items = $('.landing__attr__item'); // Selecteer de items
                const $showMore = $('#landing__attr__more'); // Selecteer de "Meer specificaties" knop

                // Verberg items afhankelijk van de resolutie
                if (isMobile) {
                    $items.each(function (index) {
                        if (index >= 8) {
                            $(this).addClass('hidden__item');
                        }
                    });
                } else {
                    $items.each(function (index) {
                        if (index >= 16) {
                            $(this).addClass('hidden__item');
                        }
                    });
                }

                // Klikfunctionaliteit voor de "Meer specificaties" knop
                $showMore.on('click', function () {
                    $items.removeClass('hidden__item'); // Toon alle items
                    $(this).hide(); // Verberg de knop na klikken
                });
            }, 500); // 500ms delay

            // Voeg een delay van 500ms toe voor de tweede functie
            setTimeout(function () {
                const isMobile = $(window).width() < 768; // Controleer of het scherm mobiel is
                const $propItems = $('.landing__prop__item'); // Selecteer de eigenschappen-items
                const $showMoreBtn = $('#show__more__btn'); // Selecteer de "Meer unieke eigenschappen" knop

                // Verberg items afhankelijk van de resolutie
                if (isMobile) {
                    $propItems.each(function (index) {
                        if (index >= 2) {
                            $(this).addClass('hidden__item');
                        }
                    });
                } else {
                    $propItems.each(function (index) {
                        if (index >= 6) {
                            $(this).addClass('hidden__item');
                        }
                    });
                }

                // Klikfunctionaliteit voor de "Meer unieke eigenschappen" knop
                $showMoreBtn.on('click', function () {
                    $propItems.removeClass('hidden__item'); // Toon alle items
                    $(this).hide(); // Verberg de knop na klikken
                });
            }, 500); // 500ms delay
        });




        $('.landing__images__slider').slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
            draggable: false,
            prevArrow: $('.landing__images__prev'),
            nextArrow: $('.landing__images__next'),
        });

        $(document).ready(function () {
            let $slider = $('.landing__related__slider');
            let $prevArrow = $('.landing__related__prev');
            let $nextArrow = $('.landing__related__next');

            // Initialiseer Slick Slider
            $slider.slick({
                dots: false,
                infinite: true,
                draggable: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                centerMode: false,
                prevArrow: $prevArrow,
                nextArrow: $nextArrow,
                responsive: [
                    {
                        breakpoint: 1499,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            autoplaySpeed: 3000,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            autoplaySpeed: 3000,
                        },
                    },
                ],
            });

            // Functie om pijlen aan te passen
            function toggleArrows() {
                let slick = $slider.slick('getSlick'); // Haal de slick-instantie op
                let slideCount = slick.slideCount; // Haal het totale aantal slides op
                let slidesToShow = slick.options.slidesToShow; // Aantal zichtbare slides

                // Pas de stijl van de pijlen aan
                if (slideCount <= slidesToShow) {
                    $prevArrow.css({ opacity: 0.5, 'pointer-events': 'none' });
                    $nextArrow.css({ opacity: 0.5, 'pointer-events': 'none' });
                } else {
                    $prevArrow.css({ opacity: 1, 'pointer-events': 'auto' });
                    $nextArrow.css({ opacity: 1, 'pointer-events': 'auto' });
                }
            }

            // Controleer pijlen na initialisatie
            toggleArrows();

            // Controleer opnieuw bij venstergrootte wijzigen
            $(window).on('resize', function () {
                toggleArrows();
            });
        });


        $(document).ready(function () {
            let $slider = $('.landing__comments__slider');
            let $prevArrow = $('.landing__comments__prev');
            let $nextArrow = $('.landing__comments__next');

            // Initialiseer Slick Slider
            $slider.slick({
                dots: false,
                infinite: true,
                draggable: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                centerMode: false,
                prevArrow: $prevArrow,
                nextArrow: $nextArrow,
                responsive: [
                    {
                        breakpoint: 1499,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            autoplaySpeed: 3000,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            autoplaySpeed: 3000,
                        },
                    },
                ],
            });

            // Functie om pijlen aan te passen
            function toggleArrows() {
                let slick = $slider.slick('getSlick'); // Haal de slick-instantie op
                let slideCount = slick.slideCount; // Haal het totale aantal slides op
                let slidesToShow = slick.options.slidesToShow; // Aantal zichtbare slides

                // Pas de stijl van de pijlen aan
                if (slideCount <= slidesToShow) {
                    $prevArrow.css({ opacity: 0.5, 'pointer-events': 'none' });
                    $nextArrow.css({ opacity: 0.5, 'pointer-events': 'none' });
                } else {
                    $prevArrow.css({ opacity: 1, 'pointer-events': 'auto' });
                    $nextArrow.css({ opacity: 1, 'pointer-events': 'auto' });
                }
            }

            // Controleer pijlen na initialisatie
            toggleArrows();

            // Controleer opnieuw bij venstergrootte wijzigen
            $(window).on('resize', function () {
                toggleArrows();
            });
        });





        $(document).ready(function () {
            let $slider = $('.landing__faq__slider');
            let $prevArrow = $('.landing__faq__prev');
            let $nextArrow = $('.landing__faq__next');

            // Initialiseer Slick Slider
            $slider.slick({
                dots: false,
                infinite: true,
                draggable: false,
                slidesToShow: 1,
                speed: 500,
                fade: true,
                autoplay: true,
                autoplaySpeed: 4000,
                centerMode: true,
                prevArrow: $prevArrow,
                nextArrow: $nextArrow,
            });

            // Functie om pijlen en teller aan te passen
            function updateArrowsAndCount() {
                let slick = $slider.slick('getSlick'); // Haal de slick-instantie op
                let slideCount = slick.slideCount; // Haal het totale aantal slides op

                if (slideCount > 1) {
                    // Toon de pijlen en voeg de telling toe
                    $prevArrow.css({ opacity: 1, 'pointer-events': 'auto' });
                    $nextArrow.css({ opacity: 1, 'pointer-events': 'auto' });
                } else {
                    // Verberg de pijlen als er minder dan 2 items zijn
                    $prevArrow.css({ opacity: 0.5, 'pointer-events': 'none' });
                    $nextArrow.css({ opacity: 0.5, 'pointer-events': 'none' });
                }
            }

            // Controleer de pijlen na initialisatie
            updateArrowsAndCount();

            // Controleer opnieuw bij venstergrootte wijzigen
            $(window).on('resize', function () {
                updateArrowsAndCount();
            });
        });

        $(document).ready(function() {
            document.getElementById('show__more__btn').addEventListener('click', function() {
                // Toon de verborgen items
                var hiddenItems = document.querySelectorAll('.hidden__item');
                hiddenItems.forEach(function(item) {
                    item.classList.remove('hidden__item');
                });

                // Verberg de knop na het tonen van de items
                this.style.display = 'none';
            });

            document.getElementById('landing__attr__more').addEventListener('click', function() {
                // Toon de verborgen items
                var hiddenItems = document.querySelectorAll('.hidden__item');
                hiddenItems.forEach(function(item) {
                    item.classList.remove('hidden__item');
                });

                // Verberg de knop na het tonen van de items
                this.style.display = 'none';
            });
        });

        $(document).ready(function () {
            $('.flexible__tabs__mobile__select').on('click', function () {
                $('.flexible__tab__list').toggleClass('open');
                $('.flexible__tab__panels').toggleClass('open');
                $(this).toggleClass('open');
            });

            $('.flexible__tab__list button').on('click', function () {
                $('.flexible__tab__list').toggleClass('open');
                $('.flexible__tab__panels').toggleClass('open');
                $(this).toggleClass('open');
            });

            const $buttons = $('.flexible__tab__list .tab'); // Selecteer alle tabs
            const $currentSelect = $('.flexible__tabs__mobile__select__current'); // Selecteer de span

            if ($buttons.length && $currentSelect.length) {
                $buttons.on('click', function () {
                    $currentSelect.text($(this).text()); // Stel de titel in van het aangeklikte tabblad
                });
            }
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

